import { Box, Typography } from "@mui/material";
import Sidebar from "../../components/sidebar/sidebar";
import AccountsDetails from "../../components/Accounts/AccountsDetails";

function Accounts() {
  return (
    <>
      {" "}
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 2 }}>
          <Box height={80} />
          <Typography variant="h4" sx={{ mr: 2 }}>
            Direct Accounts
          </Typography>
          <Box height={30} />
          <AccountsDetails />
        </Box>
      </Box>
    </>
  );
}

export default Accounts;
