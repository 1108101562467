import { Box, Typography } from "@mui/material";
import Sidebar from "../../components/sidebar/sidebar";
import HierachyAccountDetails from "../../components/Accounts/HierachyAccountDetails";
import CustomizedDatePickerForHistory from "../../components/Accounts/DatePickerHistory";

function HierarchyAccounts() {
  return (
    <>
      {" "}
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 2 }}>
          <Box height={80} />
          <Typography variant="h4" sx={{ mr: 2 }}>
            HierarchyAccounts
          </Typography>
          {/* <Box height={15} /> */}
          <CustomizedDatePickerForHistory />
          <Box mt={2} />
          <HierachyAccountDetails />
        </Box>
      </Box>
    </>
  );
}

export default HierarchyAccounts;
