import { useEffect, useState } from "react";
import axios from "axios";
import { base_url } from "../apiUtils";
import { useAppStore } from "../appStore";

const useWalletBalance = () => {
  const { setWalletBalance, walletBalance } = useAppStore();

  const fetchWalletBalance = async () => {
    try {
      const tokenStr = sessionStorage.getItem("accesstoken");
      const response = await axios.get(`${base_url}wallet/balance`, {
        headers: { accesstoken: tokenStr },
      });

      setWalletBalance(response.data.walletPoint.balance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  useEffect(() => {
    fetchWalletBalance();
  }, []); // empty dependency array ensures this effect runs only once when component mounts

  return fetchWalletBalance; // Return the function to fetch the balance
};

export default useWalletBalance;
