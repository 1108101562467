import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CustomizedDatePickerFoHistory from "./DatePickerHistory";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import AccountsDetailsTable from "./AccountsDetailsTable";

const AccountsDetails = () => {
  // const navigate = useNavigate();
  return (
    <>
      <Box>
        <CustomizedDatePickerFoHistory />
      </Box>
      <Box mt={2}>
        <AccountsDetailsTable />
      </Box>
    </>
  );
};

export default AccountsDetails;
