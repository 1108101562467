import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Typography,
  tableCellClasses,
  TablePagination,
  CircularProgress,
  Chip,
  Button,
  Box,
  TableSortLabel,
  MenuItem,
  Select,
  Divider,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import axios from "axios";
import { useAppStore } from "../../appStore";
import { base_url } from "../../apiUtils";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#dde7f1",
    color: "#477BA1",
    border: "1px solid #bdbdbd !important",
    fontWeight: "bold",
    letterSpacing: 0.5,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid #bdbdbd !important",
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F9FB",
  },
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));

const commonIconStyles = {
  fontSize: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  border: "2px solid",
  marginRight: "5px",
};

const commonChipStyle = {
  width: "90px",
  height: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "20px",
  fontSize: "12px",
  color: "#fff",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  textTransform: "capitalize",
};

const WalletStatements = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [sortOrder, setSortOrder] = useState("desc"); // Added state for sorting
  const { selectedDateHistory, setSelectedDateHistory } = useAppStore();

  const getData = async () => {
    setLoading(true);
    try {
      const url = selectedDateHistory
        ? `${base_url}wallet/user-statements?date=${selectedDateHistory}`
        : `${base_url}wallet/user-statements`;

      const response = await axios.get(url, {
        headers: { accesstoken: sessionStorage.getItem("accesstoken") },
      });
      const finalReport = response.data.statements;
      setTimeout(() => {
        setData(finalReport);
        setLoading(false);
      }, 2000);
    } catch (err) {
      console.log("Error fetching data:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [selectedDateHistory]);

  useEffect(() => {
    setSelectedDateHistory("");
    setData([]);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterType = (type) => {
    setSelectedType(type);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleSortRequest = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const filteredData = data
    .filter((statement) =>
      selectedType ? statement.type === selectedType : true
    )
    .filter((statement) =>
      selectedStatus ? statement.status === selectedStatus : true
    );

  const sortedData = filteredData.sort((a, b) => {
    if (sortOrder === "asc") {
      return new Date(a.createdAt) - new Date(b.createdAt);
    } else {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
  });

  const exportToExcel = (array) => {
    const ws = XLSX.utils.json_to_sheet(
      array.map((item) => ({
        Type: item.type,
        "Previous Balance": item.previousBalance.toFixed(2),
        "Amount (Credit / Debit)": `${
          item.type === "withdraw" ||
          item.type === "revert" ||
          item.status === "rejected"
            ? "-"
            : "+"
        }${Math.abs(item.amount)}`,
        Status: item.status,
        "Net Balance": item.netBalance.toFixed(2),
        "Date & Time": new Date(item.createdAt).toLocaleString(),
      }))
    );

    // Add styling
    const wscols = [
      { wch: 10 },
      { wch: 20 },
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 25 },
    ];
    ws["!cols"] = wscols;
    ws["!rows"] = [{ hpx: 20 }];

    // Header style
    const headerStyle = {
      fill: { fgColor: { rgb: "#FFFF00" } },
      font: { color: { rgb: "#FFFFFF" }, bold: true },
      alignment: { horizontal: "center", vertical: "center" },
    };

    ws["A1"].s = headerStyle;
    ws["B1"].s = headerStyle;
    ws["C1"].s = headerStyle;
    ws["D1"].s = headerStyle;
    ws["E1"].s = headerStyle;
    ws["F1"].s = headerStyle;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Wallet Statements");
    XLSX.writeFile(
      wb,
      `wallet_statements_${
        selectedDateHistory ? selectedDateHistory : Date.now()
      }.xlsx`
    );
  };

  const getTypeStyles = (type) => {
    const bgColor =
      (type === "deposit" && "#388e3c") ||
      (type === "withdraw" && "#ff3d00") ||
      (type === "revert" && "#ffc107");

    return {
      ...commonChipStyle,
      bgcolor: bgColor,
      "&:hover": {
        bgcolor: bgColor,
      },
    };
  };

  const getStatusIcon = (status, level) => {
    if (status === "requested" && level === "Upper") {
      return {
        icon: (
          <ArrowOutwardIcon
            sx={{
              ...commonIconStyles,
              borderColor: "#f57c00",
              color: "#f57c00",
              transform: "rotate(180deg)", // Rotate the icon 180 degrees
            }}
          />
        ),
        color: "#f57c00",
      };
    }

    switch (status) {
      case "completed":
        return {
          icon: (
            <CheckIcon
              sx={{
                ...commonIconStyles,
                borderColor: "#388e3c",
                color: "#388e3c",
              }}
            />
          ),
          color: "#388e3c",
        };
      case "requested":
        return {
          icon: (
            <ArrowOutwardIcon
              sx={{
                ...commonIconStyles,
                borderColor: "#1782FF",
                color: "#1782FF",
              }}
            />
          ),
          color: "#1782FF",
        };
      case "rejected":
        return {
          icon: (
            <CloseIcon
              sx={{
                ...commonIconStyles,
                borderColor: "#ff3d00",
                color: "#ff3d00",
              }}
            />
          ),
          color: "#ff3d00",
        };
      case "verifying":
        return {
          icon: (
            <QuestionMarkIcon
              sx={{
                ...commonIconStyles,
                borderColor: "#ffc107",
                color: "#ffc107",
              }}
            />
          ),
          color: "#ffc107",
        };
      default:
        return { icon: null, color: "default" };
    }
  };

  return (
    <>
      <Typography>Filter Statements:</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        {/* Chips for type filtering */}
        <div style={{ display: "flex", gap: "8px" }}>
          <Chip
            label="All"
            onClick={() => handleFilterType("")}
            sx={{
              ...commonChipStyle,
              bgcolor: selectedType === "" ? "#1782FF" : "#f0f0f0",
              cursor: "pointer",
            }}
          />
          {["deposit", "withdraw", "revert"].map((type) => (
            <Chip
              key={type}
              label={type}
              onClick={() => handleFilterType(type)}
              sx={{
                ...commonChipStyle,
                bgcolor:
                  selectedType === type
                    ? getTypeStyles(type).bgcolor
                    : "#bdbdbd",
                cursor: "pointer",
                "&:hover": {
                  bgcolor:
                    selectedType === type
                      ? getTypeStyles(type).bgcolor
                      : "#a3a3a3", // Use a slightly different color for hover if not selected
                },
              }}
            />
          ))}
        </div>

        <Box
          sx={{
            display: "flex",
            gap: "2px",
            alignItems: "center",
          }}
        >
          <Typography sx={{ whiteSpace: "nowrap", mr: 1 }}>
            By Status:
          </Typography>
          <Select
            size="small"
            value={selectedStatus}
            onChange={handleStatusChange}
            displayEmpty
            inputProps={{ "aria-label": "Status" }}
            sx={{ width: "100%", maxWidth: 200 }}
          >
            <MenuItem value="">All Statuses</MenuItem>
            <Divider sx={{ m: "2px 4px 0px 4px !important" }} />

            <MenuItem
              value="completed"
              sx={{ color: getStatusIcon("completed").color }}
            >
              Completed
            </MenuItem>
            <Divider sx={{ m: "2px 4px 0px 4px !important" }} />
            <MenuItem
              value="requested"
              sx={{ color: getStatusIcon("requested").color }}
            >
              Requested
            </MenuItem>
            <Divider sx={{ m: "2px 4px 0px 4px !important" }} />

            <MenuItem
              value="rejected"
              sx={{ color: getStatusIcon("rejected").color }}
            >
              Rejected
            </MenuItem>
            <Divider sx={{ m: "2px 4px 0px 4px !important" }} />

            <MenuItem
              value="verifying"
              sx={{ color: getStatusIcon("verifying").color }}
            >
              Verifying
            </MenuItem>
          </Select>
        </Box>

        {/* Export Button */}
        <Button
          variant="contained"
          sx={{
            ml: 2,
            px: 3,
            textTransform: "capitalize",
            bgcolor: "#2e7d32",

            "&:hover": {
              bgcolor: "#43a047",
            },
            whiteSpace: "nowrap",
            fontSize: "0.7rem",
          }}
          onClick={() => exportToExcel(filteredData)}
        >
          Export .xlsx <DownloadIcon sx={{ fontSize: "16px" }} />
        </Button>
      </Box>
      <TableContainer>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Table aria-label="wallet statements table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Type</StyledTableCell>
                  <StyledTableCell align="left">
                    Previous Balance
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Amount <br />
                    (Credit / Debit)
                  </StyledTableCell>
                  <StyledTableCell align="left">Net Balance</StyledTableCell>
                  <StyledTableCell align="left">
                    <TableSortLabel
                      active={true}
                      direction={sortOrder}
                      onClick={handleSortRequest}
                      sx={{ color: "#477BA1 !important" }}
                    >
                      Date & Time
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="left">Transaction Id</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((statement) => {
                    const { icon, color } = getStatusIcon(
                      statement.status,
                      statement.level
                    );
                    return (
                      <StyledTableRow key={statement._id}>
                        <StyledTableCell align="left">
                          <Chip
                            size="small"
                            label={statement.type}
                            sx={getTypeStyles(statement.type)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {statement.previousBalance.toFixed(2)}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{
                            color:
                              statement.type === "withdraw" ||
                              statement.type === "revert" ||
                              statement.status === "rejected" ||
                              (statement.type === "deposit" &&
                                statement.status === "completed" &&
                                statement.level === "Upper")
                                ? "red"
                                : statement.type === "deposit" &&
                                  statement.status === "completed"
                                ? "green"
                                : statement.status === "requested" &&
                                  statement.type === "deposit"
                                ? "#1782FF"
                                : "",
                          }}
                        >
                          {(statement.type === "withdraw" &&
                            statement.level !== "Upper") ||
                          statement.type === "revert" ||
                          (statement.type === "deposit" &&
                            statement.status === "completed" &&
                            statement.level === "Upper")
                            ? "-"
                            : statement.type === "deposit" &&
                              statement.status === "completed"
                            ? "+"
                            : statement.status === "requested" &&
                              statement.type === "deposit"
                            ? ""
                            : statement.type === "withdraw" &&
                              statement.status === "completed" &&
                              statement.level === "Upper" &&
                              statement.status === "rejected"
                            ? ""
                            : ""}
                          {Math.abs(statement.amount)}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {statement.netBalance.toFixed(2)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {new Date(statement.createdAt).toLocaleString(
                            "en-GB"
                          )}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {statement.transactionId}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              color: color,
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            {icon}
                            <span style={{ marginLeft: "5px" }}>
                              {statement.status}
                            </span>
                          </span>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={sortedData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </TableContainer>
    </>
  );
};

export default WalletStatements;
