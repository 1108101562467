import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  styled,
} from "@mui/material";
import axios from "axios";
import { base_url } from "../../apiUtils";
import { useAppStore } from "../../appStore";

const Root = styled("div")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
}));

const StyledTableContainer = styled(Paper)(({ theme }) => ({
  maxHeight: 400,
  overflowY: "auto",
}));

const TableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#dde7f1",
  color: "#477BA1",
  fontWeight: "bold",
  border: "1px solid #bdbdbd",
  letterSpacing: 0.5,
  position: "sticky",
  top: 0,
  zIndex: 1,
}));

const TableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  border: "1px solid #bdbdbd",
  padding: theme.spacing(1),
}));

const DataTable = () => {
  const [data, setData] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortColumn, setSortColumn] = useState("Date");
  const { selectedDateHistory, setSelectedDateHistory } = useAppStore();
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${base_url}game/get-account-details?date=${selectedDateHistory}`,
        {
          headers: { accesstoken: sessionStorage.getItem("accesstoken") },
        }
      );
      const finalReport = response.data.finalReport;
      const formattedData = finalReport.flatMap((userReport) =>
        userReport.Records.map((record) => ({
          ...record,
          UserName: userReport.UserName,
        }))
      );
      setData(formattedData);
    } catch (err) {
      console.log("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDateHistory) {
      getData();
    }
  }, [selectedDateHistory]);

  useEffect(() => {
    setSelectedDateHistory("");
    setData([]);
  }, []);

  const getBaseGame = (game) => {
    const match = game.match(/^[^\s]*/);
    return match ? match[0] : game;
  };

  const sortData = (data, column, direction) => {
    return [...data].sort((a, b) => {
      if (column === "Date") {
        const dateA = new Date(a.Date);
        const dateB = new Date(b.Date);
        return direction === "asc" ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });
  };

  const handleSortRequest = (column) => {
    const isAsc = sortColumn === column && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortColumn(column);
  };

  const sortedData = sortData(data, sortColumn, sortDirection);

  return (
    <Root>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledTableContainer>
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableHeadCell align="center">Sl No</TableHeadCell>
                  <TableHeadCell align="center">
                    <TableSortLabel
                      active={sortColumn === "Date"}
                      direction={sortDirection}
                      onClick={() => handleSortRequest("Date")}
                      sx={{ color: "#477BA1 !important" }}
                    >
                      Date
                    </TableSortLabel>
                  </TableHeadCell>
                  <TableHeadCell align="center">User Name</TableHeadCell>
                  <TableHeadCell align="center">Game</TableHeadCell>
                  <TableHeadCell align="center">Result</TableHeadCell>
                  <TableHeadCell align="center">Type</TableHeadCell>
                  <TableHeadCell align="center">Vyapar</TableHeadCell>
                  {/* <TableHeadCell align="center">W/R</TableHeadCell> */}
                  <TableHeadCell align="center">Winning Number</TableHeadCell>
                  <TableHeadCell align="center">Payment</TableHeadCell>
                  <TableHeadCell align="center">Balance</TableHeadCell>
                  <TableHeadCell align="center">Net Balance</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableBodyCell colSpan={11} align="center">
                      <CircularProgress />
                    </TableBodyCell>
                  </TableRow>
                ) : sortedData.length === 0 ? (
                  <TableRow>
                    <TableBodyCell
                      colSpan={11}
                      align="center"
                      sx={{ color: "red", fontWeight: "bold" }}
                    >
                      Report Not Found .. 😔
                    </TableBodyCell>
                  </TableRow>
                ) : (
                  sortedData.map((row, index) => (
                    <TableRow key={index}>
                      <TableBodyCell align="center">{index + 1}</TableBodyCell>
                      <TableBodyCell align="center">
                        {row.Date.split("-").reverse().join("/")}
                      </TableBodyCell>
                      <TableBodyCell align="center">
                        {row.UserName}
                      </TableBodyCell>
                      <TableBodyCell align="center">
                        {getBaseGame(row.Game)}
                      </TableBodyCell>
                      <TableBodyCell align="center">
                        {(row.Type?.includes("O") && row?.OpenResult) ||
                          (row.Type?.includes("C") && row?.CloseResult) ||
                          row.Result}
                      </TableBodyCell>
                      <TableBodyCell align="center">{row.Type}</TableBodyCell>
                      <TableBodyCell align="center">
                        {Number(row.Vyapar).toFixed(2)}
                      </TableBodyCell>
                      {/* <TableBodyCell align="center">
                        {row.winningRatio}
                      </TableBodyCell> */}
                      <TableBodyCell align="center" sx={{ color: "green" }}>
                        {/* {row?.Type.includes("O") &&
                          `${row.winningNumber} / ${row.OpenPana}`}
                        {row?.Type.includes("C") &&
                          `${row.winningNumber} / ${row?.ClosePana}`}
                        {!row?.Type.includes("O") &&
                          !row?.Type.includes("C") &&
                          row.Jodi} */}
                        {row?.winningNumber}
                      </TableBodyCell>
                      <TableBodyCell
                        align="center"
                        sx={{ color: row?.Payment > 0 ? "green" : "red" }}
                      >
                        {row.Payment > 0 && "+"}{" "}
                        {row?.winningNumber === "-"
                          ? "-"
                          : Number(row.Payment).toFixed(2)}
                      </TableBodyCell>
                      <TableBodyCell align="center">
                        {Number(row.Balance).toFixed(2)}
                      </TableBodyCell>
                      <TableBodyCell align="center">
                        {Number(row.NetBalance).toFixed(2)}
                      </TableBodyCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
      </Grid>
    </Root>
  );
};

export default DataTable;
