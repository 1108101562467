// import React from "react";
// import { Navigate } from "react-router-dom";
// const ProtectedRoute = ({ children }) => {
//   const result = sessionStorage.getItem("isMathLoggedin");
//   if (!result) {
//     return <Navigate to="/" />;
//   }
//   return children;
// };
// export default ProtectedRoute;

import React from "react";
import { Navigate } from "react-router-dom";
import { useAppStore } from "./appStore";

const ProtectedRoute = ({ children, requiredPermissions }) => {
  const isLoggedIn = sessionStorage.getItem("isMathLoggedin"); // Assuming this checks if the user is logged in
  const { userPermissions, profileData } = useAppStore(); // Function to retrieve user permissions

  // Allow access if the user is a systemowner
  if (profileData?.role === "systemowner") {
    return children;
  }
  console.log("requiredPermisiions", requiredPermissions);
  console.log("userPerm", userPermissions);
  // Function to check if user has required permissions
  const hasRequiredPermissions = () => {
    if (!requiredPermissions || requiredPermissions.length === 0) {
      return true; // No specific permissions required
    }
    return requiredPermissions.every((perm) =>
      userPermissions?.some((userPerm) => userPerm.path === perm)
    );
  };

  // Redirect to login if not logged in
  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  // Redirect to unauthorized page if permissions are not sufficient
  if (!hasRequiredPermissions()) {
    return <Navigate to="/unauthorized" />;
  }

  // Render children if all conditions are met
  return children;
};

export default ProtectedRoute;
