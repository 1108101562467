import { createTheme, responsiveFontSizes } from "@mui/material";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1782FF",
    },
    secondary: {
      main: "#477BA1",
    },
    success: {
      main: "#5EFB44",
    },
  },
  typography: {
    fontFamily: ["Poppins"].join(","),

    h1: {
      fontFamily: ["Poppins"].join(","),
      fontWeight: 600,
    },
    h2: {
      fontFamily: ["Poppins"].join(","),
      fontWeight: 600,
    },
    h3: {
      fontFamily: ["Poppins"].join(","),
      fontWeight: 600,
    },
    h4: {
      fontFamily: ["Poppins"].join(","),
      fontWeight: 600,
    },
    h5: {
      fontFamily: ["Poppins"].join(","),
      fontWeight: 400,
    },
    h6: {
      fontFamily: ["Poppins"].join(","),
      fontSize: "14px",
    },
    caption: {
      fontFamily: ["Poppins"].join(","),
      fontWeight: 400,
      color: "#808080",
    },
  },
});
export default responsiveFontSizes(theme);
