import { Box, Button, Typography } from "@mui/material";
import Sidebar from "../../components/sidebar/sidebar";
import CustomizedDatePickerForHistory from "../../components/Accounts/DatePickerHistory";
import WalletStatements from "../../components/Transactions/WalletStatements";

function WalletStatementPage() {
  return (
    <>
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebar />
          <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 2 }}>
            <Box height={90} />

            <Typography variant="h4">Wallet Statements</Typography>
            <Box height={10} />
            <CustomizedDatePickerForHistory />
            <Box height={30} />
            <WalletStatements />
          </Box>
        </Box>
      </div>
    </>
  );
}

export default WalletStatementPage;
