import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { base_url } from "../../apiUtils";
import { useAppStore } from "../../appStore";
import { styled } from "@mui/material/styles";

const BulletListItem = styled("li")(({ theme }) => ({
  listStyleType: "disc",
  marginLeft: theme.spacing(2), // Adds some spacing before the bullet point
}));

function SelectGames() {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [games, setGames] = useState([]);

  const {
    setGamesSelected,
    gamesSelected,
    selectedMenuId,
    setSelectedMenuInfo,
    updateWinningRatio,
  } = useAppStore();

  const [selectedGames, setSelectedGames] = useState([]);
  const [condition, setCondition] = useState(false);
  let token = sessionStorage.getItem("accesstoken");

  const getAllGames = () => {
    let result = axios
      .get(`${base_url}game/get-games`, {
        headers: {
          accesstoken: token,
        },
      })
      .then(function (response) {
        let data = response.data;
        if (data.status === "success") {
          setGames(data.games);
        }
      })
      .catch(function (error) {
        setGames([]);
      });
  };
  // const getSelectGames = () => {
  //   let result = axios
  //     .get(`${base_url}menuformation/get-single-formation/${selectedMenuId}`, {
  //       headers: {
  //         accesstoken: token,
  //       },
  //     })
  //     .then(function (response) {
  //       let data = response.data;
  //       if (data.status === "success") {
  //         setSelectedMenuInfo(data.menuformationDetails);
  //         let gameData = data.menuformationDetails?.gameName;
  //         let games = [];
  //         gameData.forEach((game) => {
  //           games.push(game._id);
  //         });
  //         setSelectedGames(games);
  //         updateWinningRatio(
  //           "superAgent",
  //           data.menuformationDetails?.winningRatio[0]?.ratio
  //         );
  //         updateWinningRatio(
  //           "bookie",
  //           data.menuformationDetails?.winningRatio[1]?.ratio
  //         );
  //         updateWinningRatio(
  //           "agent",
  //           data.menuformationDetails?.winningRatio[2]?.ratio
  //         );
  //         updateWinningRatio(
  //           "subAgent",
  //           data.menuformationDetails?.winningRatio[3]?.ratio
  //         );
  //         updateWinningRatio(
  //           "customer",
  //           data.menuformationDetails?.winningRatio[4]?.ratio
  //         );
  //       }
  //     })
  //     .catch(function (error) {
  //       setSelectedGames([]);
  //     });
  // };
  const handleSearch = (event) => {
    const keyword = event.target.value;
    setSearchKeyword(keyword);

    if (keyword === "") {
      setCondition(false);
    } else {
      setCondition(true);
    }
  };

  const handleClear = () => {
    setSearchKeyword("");
    setCondition(false);
  };

  const handleCheckboxChange = (gameId) => {
    // Check if the game is already selected
    const isGameSelected = selectedGames.includes(gameId);

    // Update the selectedGames array based on the checkbox state
    if (isGameSelected) {
      setSelectedGames((prevSelectedGames) =>
        prevSelectedGames.filter((id) => id !== gameId)
      );
    } else {
      setSelectedGames((prevSelectedGames) => [...prevSelectedGames, gameId]);
      // setGamesSelected(selectedGames);
    }
  };

  useEffect(() => {
    getAllGames();
  }, [condition]);

  useEffect(() => {
    setSelectedGames(gamesSelected);
  }, [gamesSelected]);
  useEffect(() => {
    setGamesSelected(selectedGames);
  }, [selectedGames]);

  return (
    <>
      <Grid container item xs={6} mt={2}>
        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">Insert Games :</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            value={searchKeyword}
            size="small"
            placeholder="Search by game name"
            onChange={handleSearch}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchKeyword ? (
                <InputAdornment
                  position="end"
                  onClick={handleClear}
                  style={{ cursor: "pointer" }}
                >
                  <ClearIcon />
                </InputAdornment>
              ) : null,
            }}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        {condition ? (
          <Grid container spacing={2}>
            {games
              .filter((row) => {
                const lowerCaseKeyword = searchKeyword.toLowerCase();
                return row?.gameName.toLowerCase().includes(lowerCaseKeyword);
              })
              .map((game) => (
                <Grid item md={3} xs={12} sm={6} key={game._id}>
                  <Card
                    elevation={2}
                    sx={{
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        p: 1,
                      }}
                    >
                      <Grid item sm={4}>
                        <Checkbox
                          onChange={() => handleCheckboxChange(game._id)}
                          checked={selectedGames.includes(game._id)}
                        />
                      </Grid>
                      <Grid item sm={8}>
                        <Typography
                          sx={{ color: game.gameStatus === false && "#969393" }}
                        >
                          {game.gameName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))}
          </Grid>
        ) : (
          ""
        )}
      </Box>
      {/* Display selected games */}

      <Box mt={2}>
        {selectedGames.length > 0 && (
          <>
            <Typography variant="h6">
              Selected Games: {selectedGames.length}
            </Typography>
            <Grid container spacing={2} m={0.5}>
              {selectedGames.map((gameId, index) => {
                const selectedGame = games.find((game) => game._id === gameId);
                return (
                  selectedGame && (
                    <Grid item xs={12} sm={6} md={3} lg={1.5} key={gameId}>
                      <span>
                        <span style={{ fontWeight: "bold" }}>{index + 1}</span>.{" "}
                        {selectedGame.gameName}
                      </span>
                    </Grid>
                  )
                );
              })}
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}

export default SelectGames;
