import { useEffect } from "react";

const INACTIVITY_TIME_LIMIT = 5 * 60 * 1000; // 5 minutes in milliseconds

const useInactivityLogout = () => {
  useEffect(() => {
    let inactivityTimer;

    const resetTimer = () => {
      if (inactivityTimer) {
        clearTimeout(inactivityTimer);
      }
      inactivityTimer = setTimeout(() => {
        sessionStorage.clear();
        window.location.href = "/";
      }, INACTIVITY_TIME_LIMIT);
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        resetTimer();
      } else {
        clearTimeout(inactivityTimer);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    resetTimer();

    return () => {
      clearTimeout(inactivityTimer);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
    };
  }, []);
};

export default useInactivityLogout;
