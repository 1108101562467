import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Typography,
  tableCellClasses,
  TablePagination,
  CircularProgress,
  Chip,
  Button,
  Box,
  TableSortLabel,
  MenuItem,
  Select,
  Divider,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import axios from "axios";
import { useAppStore } from "../../appStore";
import { base_url } from "../../apiUtils";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#dde7f1",
    color: "#477BA1",
    border: "1px solid #bdbdbd !important",
    fontWeight: "bold",
    letterSpacing: 0.5,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid #bdbdbd !important",
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F9FB",
  },
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));

const GameWalletStatements = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [sortOrder, setSortOrder] = useState("desc"); // Added state for sorting
  const { selectedDateHistory, setSelectedDateHistory } = useAppStore();

  const getData = async () => {
    setLoading(true);
    try {
      const url = selectedDateHistory
        ? `${base_url}game/get-user-transactions-statements?date=${selectedDateHistory}`
        : `${base_url}game/get-user-transactions-statements`;

      const response = await axios.get(url, {
        headers: { accesstoken: sessionStorage.getItem("accesstoken") },
      });
      const finalReport = response.data.statements;
      setTimeout(() => {
        setData(finalReport);
        setLoading(false);
      }, 2000);
    } catch (err) {
      console.log("Error fetching data:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [selectedDateHistory]);

  useEffect(() => {
    setSelectedDateHistory("");
    setData([]);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const handleFilterType = (type) => {
    setSelectedType(type);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleSortRequest = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const filteredData = data
    .filter((statement) =>
      selectedType ? statement.type === selectedType : true
    )
    .filter((statement) =>
      selectedStatus ? statement.status === selectedStatus : true
    );

  const sortedData = filteredData.sort((a, b) => {
    if (sortOrder === "asc") {
      return new Date(a.createdAt) - new Date(b.createdAt);
    } else {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
  });

  return (
    <>
      <TableContainer>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Table aria-label="wallet statements table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Game</StyledTableCell>
                  <StyledTableCell align="left">Number</StyledTableCell>
                  <StyledTableCell align="left">Type</StyledTableCell>
                  <StyledTableCell align="left">
                    Previous Balance
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Amount <br />
                    (Credit / Debit)
                  </StyledTableCell>
                  <StyledTableCell align="left">Net Balance</StyledTableCell>
                  <StyledTableCell align="left">
                    <TableSortLabel
                      active={true}
                      direction={sortOrder}
                      onClick={handleSortRequest}
                      sx={{ color: "#477BA1 !important" }}
                    >
                      Date & Time
                    </TableSortLabel>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((statement) => {
                    return (
                      <StyledTableRow key={statement._id}>
                        <StyledTableCell align="left">
                          {statement.game?.gameName}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            {statement.number}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {statement.type}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {statement.previousBalance.toFixed(2)}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{
                            color:
                              statement.paymentType === "plus"
                                ? "green"
                                : "red",
                          }}
                        >
                          {statement.paymentType === "plus" ? "+" : "-"}{" "}
                          {Math.abs(statement.payment).toFixed(2)}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {statement.netBalance.toFixed(2)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {new Date(statement.createdAt).toLocaleString(
                            "en-GB"
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100, 200, 250, 300]}
              component="div"
              count={sortedData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </TableContainer>
    </>
  );
};

export default GameWalletStatements;
