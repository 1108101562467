import React from "react";
import { Box, Dialog, Modal } from "@mui/material";

import { TailSpin, Triangle } from "react-loader-spinner";
import { useAppStore } from "../appStore";

function Loader() {
  const { loading } = useAppStore();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <Modal open={loading} sx={{ background: "transparent" }}>
      <Box sx={style}>
        <TailSpin
          color="#2196f3"
          visible={true}
          height="80"
          width="80"
          strokeWidth={4}
        />
      </Box>
    </Modal>
  );
}

export default Loader;
