import { useAppStore } from "../appStore";

export function GameTimeCalculation() {
  const { selectedGame, profileData } = useAppStore();
  let num;
  if (profileData?.role === "superagent") {
    num = 0;
  } else if (profileData?.role === "bookie") {
    num = 1;
  } else if (profileData?.role === "agent") {
    num = 2;
  } else {
    num = 3;
  }

  const gameTiming = () => {
    const today = new Date();
    const currentDay = today.getDay();

    // An array to map the numeric representation to the actual day names
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    // Get the name of the current day
    const dayName = daysOfWeek[currentDay];

    if (Object.keys(selectedGame).length === 0) {
    } else {
      const responseDayData = selectedGame.weekTimings.find(
        (dayData) => dayData?.day === dayName
      );
      console.log(responseDayData);
      if (responseDayData) {
        // close time
        const closeTime = responseDayData.time || "00:00";
        const customerCloseTime =
          selectedGame?.preClosingTime[num]?.closingTime || 0;
        const [hours1, minutes1] = closeTime.split(":").map(Number);
        // Subtract customer open time in minutes
        let resultMinutes1 = minutes1 - customerCloseTime;
        let resultHours1 = hours1;
        // Adjust hours if needed
        if (resultMinutes1 < 0) {
          resultHours1 -= 1;
          resultMinutes1 += 60;
        }
        const resultTime1 = `${String(resultHours1).padStart(2, "0")}:${String(
          resultMinutes1
        ).padStart(2, "0")}`;

        // close time
        //   setOpenTimeGame9(resultTime);
        let time = resultTime1;

        return time;
      } else {
        console.log("Holiday.");
        return "00:00";
      }
    }
  };
  return {
    gameTiming,
  };
}
