import {
  Box,
  TextField,
  Typography,
  Grid,
  Autocomplete,
  Checkbox,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";

import { useEffect, useState } from "react";
import axios from "axios";
import { base_url } from "../../../apiUtils";
import SelectGames from "../../menu/selectGames";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../../appStore";
import { toast } from "react-toastify";

function Share() {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState();
  const navigate = useNavigate();
  const token = sessionStorage.getItem("accesstoken");
  const [users, setUsers] = useState([]);
  const [shareUsers, setShareUsers] = useState([]);

  const [value, setValue] = useState("minus");
  const [percentage, setPercentage] = useState("");
  const { gamesSelected, setGamesSelected } = useAppStore();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleUserSearch = async (event) => {
    const inputValue = event.target.value;

    // Check if inputValue contains only numbers
    const isNumeric = /^[0-9]+$/.test(inputValue);

    const params = {};

    if (isNumeric) {
      // If inputValue contains only numbers, include it in the params
      params.contactValue = inputValue;
    } else {
      // If inputValue contains non-numeric characters, include it in the name field
      params.name = inputValue;
    }

    const response = await axios.get(`${base_url}user/searched-users`, {
      params: params,

      headers: { accesstoken: token },
    });
    const user = response.data.searchedData;
    setUsers(user);
    return response.data;
  };

  const handleShareUserSearch = async (event) => {
    const inputValue = event.target.value;

    // Check if inputValue contains only numbers
    const isNumeric = /^[0-9]+$/.test(inputValue);

    const params = {};

    if (isNumeric) {
      // If inputValue contains only numbers, include it in the params
      params.contactValue = inputValue;
    } else {
      // If inputValue contains non-numeric characters, include it in the name field
      params.name = inputValue;
    }

    const response = await axios.get(`${base_url}user/searched-users`, {
      params: params,

      headers: { accesstoken: token },
    });
    const user = response.data.searchedData;
    setShareUsers(user);
    return response.data;
  };
  // const handleUserChange = (event) => {
  //   const inputUser = event.target.value;
  //   setSearchTerm(inputUser);
  //   if (inputUser === "") {
  //     formik.handleChange(event);
  //   } else {
  //     formik.setFieldValue("user", inputUser);
  //   }
  // };
  const filterUser = (e, v) => {
    if (v) {
      setSelectedUserId(v._id);
    } else {
      // getUser();
    }
  };
  const handlePercentageChange = (e) => {
    setPercentage(e.target.value);
  };

  const handleSubmit = () => {
    let shareHolders = [];
    selectedUsers.map((user) => shareHolders.push(user._id));
    axios
      .post(
        `${base_url}/share/create-share`,
        {
          user: selectedUserId,
          shareHolders: shareHolders,
          type: value,
          percentage: percentage,
          selectedGames: gamesSelected,
        },
        {
          headers: {
            accesstoken: token,
          },
        }
      )
      .then(function (response) {
        let data = response.data;
        if (data.status === "success") {
          toast.success("Share Created Successfully");
          navigate("/share-list");
          setSelectedUserId("");
          setSelectedUsers([]);
          setValue("");
          setPercentage("");
          setGamesSelected([]);
        }
      })
      .catch(function (error) {
        toast.error("Unable to create");
      });
  };

  return (
    <>
      <Box mt={2}>
        {" "}
        <Grid container>
          <Grid xs={2} sm={2} sx={{ display: "flex", alignItems: "center" }}>
            <Typography>User :</Typography>
          </Grid>
          <Grid item xs={10} sm={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={users}
              getOptionLabel={(users) =>
                `${users.name} (${users?.primaryPhone?.contactValue})` || ""
              }
              onChange={(e, v) => {
                filterUser(e, v);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  width="50%"
                  label="Search"
                  onChange={(e) => {
                    handleUserSearch(e);
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option._id === value._id} // Customize equality test
            />
          </Grid>

          <Grid container mt={3}>
            <Grid
              item
              xs={2}
              sm={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography>Share Holders :</Typography>
            </Grid>
            <Grid item xs={10} sm={4}>
              <Autocomplete
                disablePortal
                multiple
                id="combo-box-demo"
                options={shareUsers}
                getOptionLabel={(shareUsers) =>
                  `${shareUsers.name} (${shareUsers?.primaryPhone?.contactValue})` ||
                  ""
                }
                onChange={(_, newValue) => {
                  setSelectedUsers(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Search"
                    onChange={(e) => {
                      handleShareUserSearch(e);
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                } // Customize equality test
              />
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid
              item
              xs={2}
              sm={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography>Type :</Typography>
            </Grid>
            <Grid item xs={10} sm={4}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="minus"
                    control={<Radio />}
                    label="Minus"
                  />
                  <FormControlLabel
                    value="plus"
                    control={<Radio />}
                    label="Plus"
                  />
                  <FormControlLabel
                    value="neutral"
                    control={<Radio />}
                    label="Neutral"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid
              item
              xs={2}
              sm={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {" "}
              <Typography>Percentage :</Typography>
            </Grid>
            <Grid>
              <TextField size="small" onChange={handlePercentageChange} />
            </Grid>
          </Grid>
        </Grid>
        <SelectGames />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 10 }}>
        <Box mr={2}>
          <Button
            variant="Outlined"
            onClick={() => {
              navigate("/users");
            }}
            sx={{
              textTransform: "capitalize",
              bgcolor: "red",
              color: "#fff",
              "&:hover": {
                // Styles for the hover state
                bgcolor: "darkred", // Change the background color on hover
              },
            }}
          >
            Cancel
          </Button>
        </Box>
        <Box>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default Share;
