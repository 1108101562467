import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppStore } from "../../appStore";
const ConfirmationDialog = () => {
  const { successDialog, setSuccessDialog } = useAppStore();
  return (
    <Dialog open={successDialog}>
      <Box sx={{ width: { md: "400px" } }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: -3,
          }}
        >
          <DialogTitle sx={{ fontWeight: "bold" }}>Confirmation</DialogTitle>
          <DialogActions>
            <Button onClick={() => setSuccessDialog(false)} color="error">
              <CloseIcon />
            </Button>
          </DialogActions>
        </Box>
        <DialogContent sx={{ textAlign: "center" }}>
          <Box
            sx={{
              width: "200px",
              height: "200px",
              background: "white",
              mx: "auto",
              objectFit: "contain",
            }}
          >
            <img
              src="/images/success.gif"
              alt=""
              width={"100%"}
              style={{ display: "block", margin: "0 auto" }}
            />
          </Box>
          <Typography>
            Your submission has been confirmed successfully!
          </Typography>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
export default ConfirmationDialog;
