import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tooltip,
} from "@mui/material";
import {
  Menu as MenuIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Group as GroupIcon,
  Summarize as SummarizeIcon,
  Report as ReportIcon,
  Campaign as CampaignIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Gamepad as GamepadIcon,
  Widgets as WidgetsIcon,
  Settings as SettingsIcon,
  InsertInvitation as InsertInvitationIcon,
  Dashboard,
  Key as KeyIcon,
} from "@mui/icons-material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ProfileMenu from "../profilemenu/profileMenu";
import { useAppStore } from "../../appStore";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import MessageIcon from "@mui/icons-material/Message";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { base_url } from "../../apiUtils";
import axios from "axios";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

const drawerWidth = 290;

const Sidebar = (props) => {
  const { window } = props;
  const navigate = useNavigate();
  const {
    profileData,
    userPermissions,
    walletBalance,
    setWalletBalance,
    setCreditLimit,
  } = useAppStore();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleCopy = () => {
    navigator.clipboard.writeText(profileData?.userId || "");
    toast.success("user id copied.");
  };

  const toggleSubItems = (index) => {
    setSelectedIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  let userManagementItems = [
    { path: "/dashboard", text: "Dashboard", icon: <Dashboard /> },
    { path: "/permissions", text: "Permissions", icon: <KeyIcon /> },
    { path: "/users", text: "Users", icon: <GroupIcon /> },
    profileData?.role === "systemowner" && {
      text: "Settings",
      icon: <SettingsIcon />,
      subItems: [
        { path: "/commissionsettings", text: "Commission Settings" },
        { path: "/walletsettings", text: "Common Wallet Settings" },
      ],
    },

    {
      text: "Wallet",
      icon: <AccountBalanceWalletIcon />,
      // path: "/wallet",
      subItems: [
        { path: "/wallet", text: "Wallet Details" },
        { path: "/depositrequest", text: "Deposit Request" },
        { path: "/withdrawrequest", text: "Withdraw Request" },
      ],
    },

    {
      text: "Game",
      icon: <GamepadIcon />,
      subItems: [
        profileData?.role === "systemowner" && {
          path: "/addGame",
          text: "Add Game",
        },
        profileData?.role === "systemowner" && {
          path: "/preclosing-time-list",
          text: "Custom Preclosing",
        },
        profileData?.role === "systemowner" && {
          path: "/edit-games",
          text: "Edit Game",
        },
        profileData?.role !== "systemowner" && {
          path: "/playGame",
          text: "Play Game",
        },
      ].filter(Boolean),
    },
    {
      text: "Menu",
      icon: <WidgetsIcon />,
      subItems: [
        profileData?.role === "systemowner" && {
          path: "/addMenu",
          text: "Create Menu",
        },
        profileData?.role === "systemowner" && {
          path: "/menu-formation",
          text: "Menu / Ratio",
        },
        profileData?.role === "systemowner" && {
          path: "/winning-ratio-settings",
          text: "Special Case Ratio",
        },
        { path: "/selectMenu", text: "Choose Menu" },
      ].filter(Boolean),
    },
    { path: "/reports", text: "Reports", icon: <AssessmentIcon /> },
    { path: "/result-declaration", text: "Results", icon: <SummarizeIcon /> },

    { path: "/place-bets", text: "Place Bets", icon: <LibraryAddIcon /> },
    {
      text: "Accounts",
      icon: <AccountBoxIcon />,
      subItems: [
        { path: "/accounts", text: "Direct Accounts" },
        profileData?.role === "systemowner" && {
          path: "/hierarchy-syso-accounts",
          text: "Hierarchy Accounts",
        },
      ],
    },
    {
      text: "Transactions",
      icon: <ReceiptLongIcon />,
      subItems: [
        { path: "/lower-game-play-details", text: "Transaction Details" },
        {
          path: "/wallet-statements",
          text: "Wallet Statements",
        },
        {
          path: "/game-wallet-statements",
          text: "Game Statements",
        },
      ],
    },
    { path: "/annoucements", text: "Announcements", icon: <CampaignIcon /> },
    { path: "/send-message", text: "Message-Users", icon: <MessageIcon /> },
    {
      path: "/declare-holiday",
      text: "Holiday",
      icon: <InsertInvitationIcon />,
    },
  ].filter(Boolean);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  const handleOpenLogoutDialog = () => {
    setIsLogoutDialogOpen(true);
  };

  const handleCloseLogoutDialog = () => {
    setIsLogoutDialogOpen(false);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    sessionStorage.removeItem("sessionId");
    navigate("/");
    setIsLogoutDialogOpen(false);
  };

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    const index = userManagementItems.findIndex(
      (item) => item.path === location.pathname
    );
    setSelectedIndex(index);
  }, [location.pathname]);

  // Filter menu items based on userPermissions
  const filteredItems = userManagementItems
    .map((item) => {
      if (!item.subItems) {
        // No subitems, check permission directly
        if (userPermissions && userPermissions.length > 0) {
          const permission = userPermissions.find(
            (perm) => perm.path === item.path && perm.access
          );
          return permission ? item : null;
        }
        if (profileData?.role === "systemowner") {
          return item; // Show all items for system owner
        }
      } else {
        // Has subitems, filter subitems based on permissions
        const filteredSubItems = item.subItems
          .map((subItem) => {
            if (userPermissions && userPermissions.length > 0) {
              const permission = userPermissions.find(
                (perm) => perm.path === subItem.path && perm.access
              );
              return permission ? subItem : null;
            }
            if (profileData?.role === "systemowner") {
              return subItem; // Show all subitems for system owner
            }
          })
          .filter(Boolean);

        if (filteredSubItems.length > 0) {
          return { ...item, subItems: filteredSubItems };
        }
        // return null; // If no subitems are visible, hide the main item as well
      }
    })
    .filter(Boolean);

  useEffect(() => {
    // Navigate to welcome if no items are visible
    if (filteredItems.length === 0) {
      navigate("/welcome");
    }
  }, []);

  let tokenStr = sessionStorage.getItem("accesstoken");

  const getWalletPoints = async () => {
    const response = await axios.get(`${base_url}wallet/balance`, {
      headers: { accesstoken: tokenStr },
    });
    const request = response.data;
    setWalletBalance(request?.walletPoint?.balance);
    setCreditLimit(request?.creditLimit);
    return response.data;
  };

  useEffect(() => {
    getWalletPoints();
    // fetchMessages();
  }, []);

  userManagementItems = filteredItems;

  const drawer = (
    <div
      style={{
        backgroundColor: "#edf1f5",
        height: "100%",
        backdropFilter: "blur(10px)",
      }}
    >
      <img
        src="/images/sm-logo.png"
        alt="Logo"
        style={{
          height: "80px",
          width: "80px",
          top: "15px",
          left: "90px",
          position: "relative",
        }}
      />
      <Typography
        sx={{
          color: "#000000",
          mt: "20px",
          textAlign: "center",
          letterSpacing: 1,
          fontSize: "20px",
        }}
      >
        SMIndia
      </Typography>
      <Box mt={1}>
        <List sx={{ m: "0px 28px" }}>
          {userManagementItems?.map((item, index) => (
            <React.Fragment key={item.text}>
              <ListItem
                sx={{
                  m: 0.5,
                  p: "2px 10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor:
                    selectedIndex === index ? "#dde7f1" : undefined,
                  color: selectedIndex === index ? "#447BA1" : undefined,
                  borderRadius: 2,
                  cursor: "pointer",
                }}
                onClick={() => toggleSubItems(index)}
              >
                <Link
                  to={item.path}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ListItemIcon
                      sx={{
                        color: selectedIndex === index ? "#477BA1" : "#C5CDDA",
                        mr: -2,
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      selected={selectedIndex === index}
                      sx={{
                        whiteSpace: "nowrap",
                        fontWeight: "500 !important",
                      }}
                    />
                  </Box>
                </Link>
                {item.subItems ? (
                  <Box sx={{ alignItems: "flex-end", cursor: "pointer" }}>
                    {selectedIndex === index ? (
                      <ExpandLessIcon
                        sx={{
                          color: selectedIndex === index ? "#477BA1" : "#000",
                          pt: 0.5,
                        }}
                      />
                    ) : (
                      <ExpandMoreIcon
                        sx={{
                          color: selectedIndex === index ? "#477BA1" : "#000",
                          pt: 0.5,
                        }}
                      />
                    )}
                  </Box>
                ) : null}
              </ListItem>
              {item.subItems && selectedIndex === index && (
                <List>
                  {item.subItems.map((subItem, subIndex) => (
                    <ListItem key={subItem.text} sx={{ p: "1px 0px" }}>
                      <Link
                        to={subItem.path}
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <ListItemIcon>{subItem.icon}</ListItemIcon>
                          <ListItemText
                            primary={subItem.text}
                            selected={selectedIndex === subIndex}
                            sx={{
                              whiteSpace: "nowrap",
                              fontWeight: "500 !important",
                            }}
                          />
                        </Box>
                      </Link>
                    </ListItem>
                  ))}
                </List>
              )}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer - 1,
          backgroundColor: "#FFFFFF",
          p: 0.5,
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: { xs: 0, md: 2 },
              display: { md: "none" },
              color: "#88AABD",
            }}
          >
            <MenuIcon />
          </IconButton>
          <Grid container>
            <Grid item xs={0} sm={2}></Grid>
            <Grid item xs={12} sm={10}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: { xs: "12px", sm: "16px" },
                      display: { xs: "grid", md: "flex" },
                      ml: { xs: 0, sm: 5, md: 10 },
                    }}
                  >
                    User Id:{" "}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#1782FF",
                      fontWeight: "bold",
                      fontSize: { xs: "14px", sm: "16px" },
                      ml: 1,
                    }}
                  >
                    {profileData?.userId}
                  </Typography>
                  <Tooltip title="Copy user id">
                    <IconButton onClick={handleCopy}>
                      <ContentCopyIcon
                        sx={{ color: "#f57c00", fontSize: "16px" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
                {profileData?.role !== "systemowner" && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: { xs: "12px", sm: "16px" },
                        display: { xs: "grid", md: "flex" },
                        ml: { xs: 0, sm: 5, md: 10 },
                      }}
                    >
                      Available Points:{" "}
                    </Typography>

                    <Typography
                      sx={{
                        color: "#e53935",
                        fontWeight: "bold",
                        fontSize: { xs: "14px", sm: "16px" },
                        ml: 1,
                      }}
                    >
                      {Number(walletBalance).toFixed(2)}
                    </Typography>
                  </Box>
                )}

                <ProfileMenu />
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#edf1f5",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // mr: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          mt: 8,
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
      <Dialog open={isLogoutDialogOpen} onClose={handleCloseLogoutDialog}>
        <DialogTitle>Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogoutDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Sidebar;
