import { useEffect, useState } from "react";
import axios from "axios";
import useWalletBalance from "./getWalletBalance";
import { base_url } from "../apiUtils";

// Custom hook for making the API call
export function useCalculateAmount() {
  const [calculation, setCalculation] = useState(null);
  const fetchBalance = useWalletBalance();

  useEffect(() => {
    if (calculation) {
      fetchBalance();
    }
  }, [calculation]);

  const calculateAmount = async (requestData) => {
    console.log(requestData);

    try {
      const response = await axios.post(`${base_url}game/cal`, requestData, {
        headers: {
          accesstoken: sessionStorage.getItem("accesstoken"),
        },
      });

      const data = response.data.calculation;
      console.log("calculation", data);

      setCalculation(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  return { calculateAmount };
}

export function usePlaceBetsCalculateAmount() {
  const [calculation, setCalculation] = useState(null);
  const fetchBalance = useWalletBalance();

  useEffect(() => {
    if (calculation) {
      fetchBalance();
    }
  }, [calculation]);

  const placeBetsCalculateAmount = async (requestData) => {
    console.log(requestData);

    try {
      const response = await axios.post(
        `${base_url}game/place-bets-cal`,
        requestData,
        {
          headers: {
            accesstoken: sessionStorage.getItem("accesstoken"),
          },
        }
      );

      const data = response.data;
      console.log("calculation", data);

      setCalculation(data);
      return data?.status;
    } catch (error) {
      console.log(error.message);
    }
  };

  return { placeBetsCalculateAmount };
}

export const getOpenAndCloseGameService = async (name) => {
  try {
    const response = await axios.get(
      `${base_url}game/get-open-close-game`,

      {
        params: { name: name },
        headers: {
          accesstoken: sessionStorage.getItem("accesstoken"),
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
