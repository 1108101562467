import React from "react";
import GameWalletStatements from "../../components/Transactions/GameWalletStatements";
import Sidebar from "../../components/sidebar/sidebar";
import { Box, Typography } from "@mui/material";
import CustomizedDatePickerForHistory from "../../components/Accounts/DatePickerHistory";

const GameWalletStatementsPage = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 2 }}>
        <Box height={90} />

        <Typography variant="h4">Game Wallet Statements</Typography>
        <Box height={10} />
        <CustomizedDatePickerForHistory />
        <Box height={30} />
        <GameWalletStatements />
      </Box>
    </Box>
  );
};

export default GameWalletStatementsPage;
