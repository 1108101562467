import { Box, Button, Typography } from "@mui/material";
import Sidebar from "../../components/sidebar/sidebar";
import Share from "../../components/settings/share/share";
import { useNavigate } from "react-router-dom";
import ViewListIcon from "@mui/icons-material/ViewList";
import LowerGameDetails from "../../components/Transactions/LowerGameDetails";
import CustomizedDatePickerForHistory from "../../components/Accounts/DatePickerHistory";

function LowerGameDetailsPage() {
  return (
    <>
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebar />
          <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 2 }}>
            <Box height={90} />

            <Typography variant="h4">CB Report</Typography>
            <Box height={30} />
            <CustomizedDatePickerForHistory />

            <Box height={30} />
            <LowerGameDetails />
          </Box>
        </Box>
      </div>
    </>
  );
}

export default LowerGameDetailsPage;
